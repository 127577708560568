<template>
    <div class="resultPage">
        <img src="../../assets/invoice1.png" />
        <span class="title">提交成功</span>

        <span class="desc">发票已经提交开票</span>
        <!-- 获取一下是否已经开过发票了 -->
        <!-- <Button class="mar-t-40" color="#2b72ff" block @click="viewMyInvoice">查看我的发票</Button>       -->
        <Button class="mar-t-40" color="#2b72ff" block @click="$router.push('/my')">返回我的首页</Button>
  </div>
</template>

<script>
import { Button } from "vant";
    export default {
        name:'invoiceSuccess',
        components:{Button},
        methods:{
            viewMyInvoice(){
                this.$router.push('/previewInvoice?id=1')
            },            
        }
    }
</script>

<style lang="less" scoped>
@import "./common.less";
.resultPage{
    height: 100vh!important;
}
</style>